import { Divider, Paper, Table, TableContainer } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PaginationTable from '~/components/PaginationTable'
import TableFilters from '~/components/Table/TableFilters'
import TableHeader from '~/components/TableHeader'
import useTable from '~/hooks/useTable'
import { useUserPermission } from '~/hooks/useUserPermission'
import { useAzureDeviceListOverviewQuery } from '../query'
import { AzureDeviceHeadCells } from './AzureDeviceHeadCells'
import DeviceTableBody from './AzureDeviceTableBody'
import DeviceToolbarButtonsAndModals from './DeviceToolbarButtons'
import LoopIcon from '~/components/Button/LoopIcon'
import ToggleButton from '~/components/Button/ToggleButton'

const localization = 'pages.device.'

const AzureDeviceTable = () => {
  const { t } = useTranslation()
  const [fillterStatus, setFillterStatus] = useState('')
  const [selected, setSelected] = useState([])
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false)

  const [open, setOpen] = useState(false)
  const [deviceToDeregist, setDeviceToDeregist] = useState(null)

  const [openInRepairModal, setOpenInRepairModal] = useState(false)
  const [deviceToInRepair, setDeviceToInRepair] = useState(null)

  const [openIsRepairModal, setOpenIsRepairModal] = useState(false)

  const [openReRegisterDeviceModal, setOpenReRegisterDeviceModal] = useState(false)

  const [isFetchingDevice, setIsFetchingDevice] = useState(
    localStorage.getItem('IS_FETCHING_DEVICE') === 'true'
  )
  const [refectchInterval, setRefectchInterval] = useState(0)

  const { userAbility } = useUserPermission()
  AzureDeviceHeadCells[0].clickable = userAbility.devices.canBulkDeregister

  const {
    data,
    search,
    isLoading,
    order,
    page,
    orderBy,
    searchHandler,
    refetch: refetchDeviceList,
    sortHandler,
    changePageHandler,
    onEnterSearch
  } = useTable({
    query: useAzureDeviceListOverviewQuery,
    initialStates: {
      order: 'desc',
      page: 1,
      orderBy: 'created_at'
    },
    queryAttributes: {
      status: fillterStatus
    },
    additionalAttributes: {
      refectchInterval
    }
  })

  useEffect(() => {
    if (isFetchingDevice) {
      refetchDeviceList()
      setRefectchInterval(5000)
    } else {
      setRefectchInterval(0)
    }
  }, [isFetchingDevice, refetchDeviceList])

  const devices = useMemo(() => {
    return data ? data.data : []
  }, [data])

  const deregistADivice = useCallback((_, value) => {
    setOpen(true)
    setDeviceToDeregist(value)
  }, [])

  // SELECT ALL CLICK
  const handleSelectAllClick = event => {
    setIsSelectAllSelected(prevIsSelectAllSelected => {
      const newIsSelectAllSelected = !prevIsSelectAllSelected
      const deviceIds = newIsSelectAllSelected
        ? devices
            .filter(item => item.trc_managed === true && item.state !== 'deleting')
            .map(device => device.id)
        : []
      setSelected(deviceIds)
      return newIsSelectAllSelected
    })
  }

  // HANDLE IN-REPAIR SECTION
  const repairDevice = useCallback((_, value) => {
    setOpenInRepairModal(true)
    setDeviceToInRepair(value)
  }, [])

  // HANDLE IS-REPAIR SECTION
  const isRepairDevice = useCallback((_, value) => {
    setOpenIsRepairModal(true)
    setDeviceToInRepair(value)
  }, [])

  // HANDLE RE-REGISTER SECTION
  const isReRegisterDevice = useCallback((_, value) => {
    setOpenReRegisterDeviceModal(true)
    setDeviceToInRepair(value)
  }, [])

  const selectGroup = useMemo(() => {
    return [
      {
        label: 'Entity',
        setValue: setFillterStatus,
        dataSet: [
          { name: 'All', id: '' },
          { name: 'Complete', id: 'complete' },
          { name: 'Error', id: 'error' }
        ],
        value: fillterStatus,
        disabled: false,
        hidden: false
      }
    ]
  }, [fillterStatus])

  const onCaptureDevices = useCallback(() => {
    setIsFetchingDevice(v => {
      localStorage.setItem('IS_FETCHING_DEVICE', !v)
      return !v
    })
  }, [setIsFetchingDevice])

  return (
    <>
      <DeviceToolbarButtonsAndModals
        selected={selected}
        open={open}
        openInRepairModal={openInRepairModal}
        deviceToDeregist={deviceToDeregist}
        deviceToInRepair={deviceToInRepair}
        openIsRepairModal={openIsRepairModal}
        openReRegisterDeviceModal={openReRegisterDeviceModal}
        setOpen={setOpen}
        setSelected={setSelected}
        setDeviceToInRepair={setDeviceToInRepair}
        setIsSelectAllSelected={setIsSelectAllSelected}
        setDeviceToDeregist={setDeviceToDeregist}
        setOpenIsRepairModal={setOpenIsRepairModal}
        setOpenInRepairModal={setOpenInRepairModal}
        setOpenReRegisterDeviceModal={setOpenReRegisterDeviceModal}
        refetchDeviceList={refetchDeviceList}
        setIsFetchingDevice={setIsFetchingDevice}
      />

      <Paper elevation={1}>
        <TableFilters
          {...{
            selectGroup,
            search,
            searchHandler
          }}
          onEnterSearch={onEnterSearch}
          showDatePicker={true}
          searchPlaceHolder={t('general.searchFor') + t('general.serials_or_AadDeviceId')}
          page='azure-devices-school-user'
        >
          <ToggleButton
            content={<LoopIcon isFetching={isFetchingDevice} />}
            value='check'
            selected={isFetchingDevice}
            onChange={onCaptureDevices}
          />
        </TableFilters>

        <Divider />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <TableHeader
              headCells={AzureDeviceHeadCells}
              onRequestSort={sortHandler}
              order={order}
              orderBy={orderBy}
              localization={localization}
              isSelectAllSelected={isSelectAllSelected}
              handleSelectAllClick={handleSelectAllClick}
            />
            <DeviceTableBody
              isLoading={isLoading}
              devices={devices}
              onDeregisClick={deregistADivice}
              onRepairClick={repairDevice}
              onIsRepairClick={isRepairDevice}
              onIsReRegisterDeviceClick={isReRegisterDevice}
              setSelected={setSelected}
              selected={selected}
            />
          </Table>
        </TableContainer>
        <PaginationTable
          data={data}
          page={page}
          size='medium'
          handleChangePage={changePageHandler}
        />
      </Paper>
    </>
  )
}
export default AzureDeviceTable
