import { Stack, Typography } from '@mui/material'
import CustomModal from '~/components/Modal/CustomModal'
import { LoadingButton } from '~/components/Button/LoadingButton'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useReRegisterDeviceMutation } from '../../mutation'

const ModalReRegister = ({
  openReRegisterDeviceModal,
  handleCloseReRegisterDeviceModal,
  device,
  setOpenReRegisterDeviceModal
}) => {
  const { t } = useTranslation()

  const { mutateAsync: reRegisterDeviceMute, isLoading } = useReRegisterDeviceMutation()

  const handleSubmitInRepairModal = async () => {
    if (device === null) {
      setOpenReRegisterDeviceModal(false)
      return
    }

    await reRegisterDeviceMute(device?.id)

    setOpenReRegisterDeviceModal(false)
  }

  return (
    <CustomModal open={openReRegisterDeviceModal} onClose={handleCloseReRegisterDeviceModal}>
      <Typography variant='h6'>
        {t('pages.device.reRegisterDevice')}: {device?.serial} ?
      </Typography>
      <Stack sx={{ mt: 2 }} direction='row-reverse' gap={1}>
        <LoadingButton onClick={handleCloseReRegisterDeviceModal}>
          {t('pages.organisation.cancel')}
        </LoadingButton>
        <LoadingButton onClick={handleSubmitInRepairModal} loading={isLoading}>
          {t('pages.organisation.confirm')}
        </LoadingButton>
      </Stack>
    </CustomModal>
  )
}

ModalReRegister.propTypes = {
  openReRegisterDeviceModal: PropTypes.bool,
  handleCloseReRegisterDeviceModal: PropTypes.func,
  device: PropTypes.object,
  setOpenReRegisterDeviceModal: PropTypes.func
}

export default ModalReRegister
