import PropTypes from 'prop-types'
import useTable from '~/hooks/useTable'
import { useAzureGroupTagQuery } from '~/pages/Device/New/query'
import StudentGroupTag from '.'

const EditGroupTag = ({ open, setOpen, organisationId, organisationDetail }) => {
  const { data: groupTags } = useTable({
    query: useAzureGroupTagQuery,
    queryAttributes: {
      type: 'default_group_tag'
    }
  })

  const { data: loanerGroupTags } = useTable({
    query: useAzureGroupTagQuery,
    queryAttributes: {
      type: 'default_group_tag_loaner'
    }
  })

  const { data: staffGroupTags } = useTable({
    query: useAzureGroupTagQuery,
    queryAttributes: {
      type: 'default_group_tag_staff'
    }
  })

  return (
    <StudentGroupTag
      defaultStudentGroupTags={groupTags}
      groupTagsLoaner={loanerGroupTags}
      grouptagStaff={staffGroupTags}
      open={open}
      setOpen={setOpen}
      organisationId={organisationId}
      organisationDetail={organisationDetail}
    />
  )
}

EditGroupTag.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  organisationId: PropTypes.number,
  organisationDetail: PropTypes.object
}

export default EditGroupTag
