import adminAxiosClient from './adminBase'
import axiosClient from './base'

export const authenticateWithMicrosoft = accessToken =>
  axiosClient.post('/api/auth/microsoft', {
    access_token: accessToken
  })

export const authenticateWithGoogle = data =>
  axiosClient.post('api/sso/google/authorize', { ...data })

export const requestGoogleOAuth = () => axiosClient.post('/api/sso/google')

export const getUserProfile = () => axiosClient.get('api/me')
export const updateActiveTenantApi = id => axiosClient.post('api/me/update-active-tenant', { id })

export const updateAzureTenantRegistrationContactInfo = data =>
  axiosClient.post('api/registration/update-azure-user', { ...data })
export const updateInformationGoogleUserApi = data =>
  axiosClient.post('api/registration/update-google-user', { ...data })
export const registerAzureUser = data => axiosClient.post('api/registration/azure', { ...data })

// GOOGLE APIS
export const listGoogleDevicesApi = params => axiosClient.get('api/google/devices', { params })
export const showGoogleDeviceApi = id => axiosClient.get(`api/google/devices/${id}`)
export const createGoogleDeviceApi = data => axiosClient.post('api/google/devices', { ...data })
export const deleteGoogleDeviceApi = id => axiosClient.delete(`api/google/devices/${id}`)
export const bulkDeleteGoogleDeviceApi = ids =>
  axiosClient.post('api/google/devices/bulk-delete', { id: [...ids] })
export const listGoogleHardwaresApi = params => axiosClient.get('api/google/hardwares', { params })
export const registerGoogleUser = data => axiosClient.post('api/registration/google', { ...data })

export const listDevicesApi = params => axiosClient.get('api/devices', { params })
export const createDeviceApi = data => axiosClient.post('api/devices', { ...data })
export const showDeviceApi = id => axiosClient.get(`api/devices/${id}`)
export const deleteDeviceApi = id => axiosClient.delete(`api/devices/${id}`)
export const bulkDeleteDeviceApi = ids =>
  axiosClient.post('api/devices/bulk-delete', { id: [...ids] })
export const bulkDeleteDevicesByCsvUploadApi = (id, data, locale = 'nl') => {
  return axiosClient.post(`api/tenants/${id}/devices/bulk-delete/upload?locale=${locale}`, data)
}
export const bulkDeleteDevicesByCsvDownloadApi = id => {
  return axiosClient.get(`api/tenants/${id}/devices/bulk-delete/download`)
}
export const bulkDeleteDevicesByCsvApi = (id, data) => {
  return axiosClient.delete(`api/tenants/${id}/devices/bulk-delete/`, { data })
}
export const repairDeviceApi = data => axiosClient.post('api/devices/repair-device', { ...data })
export const reRegisterApi = id => axiosClient.post(`api/devices/re-register/${id}`)
export const getAzureGroupTag = () => axiosClient.get('api/devices/group-tags')

export const registerTenantApplication = (accessToken, registrationToken) =>
  axiosClient.post('api/registration/opt-in', {
    access_token: accessToken,
    registration_token: registrationToken
  })

export const reRegisterTenantApplication = data =>
  axiosClient.post('api/registration/re-register-application', { ...data })

export const grantTenantApplicationPermission = registrationToken =>
  axiosClient.post('api/registration/grant-application-permission', {
    registration_token: registrationToken
  })

// HEALTH CHECK APIs
export const getHealthCheckStatuses = tenantId =>
  axiosClient.get(`api/health-check/${tenantId}/statuses`)

export const healthCheckSyncData = tenantId =>
  axiosClient.post(`api/health-check/${tenantId}/sync-data`)

// TENANT APIs
export const syncDevices = tenantId =>
  axiosClient.post(`api/azure/tenants/${tenantId}/sync-devices`)

export const getTenantInfo = id => axiosClient.get(`api/tenants/${id}`)
export const grantApplicationPermission = id =>
  axiosClient.post(`api/tenants/${id}/grant-application-permission`)

// OVERVIEW DASHBOARD
export const getAdminProfile = () => adminAxiosClient.get('api/overview/admin-profile')
export const adminLogin = data => adminAxiosClient.post('api/overview/auth', { ...data })
export const adminViewTenant = params => adminAxiosClient.get('api/overview/tenant', { params })
export const overviewReRegisterTenantApplication = data =>
  adminAxiosClient.post('api/overview/registration/reregister-application', { ...data })

export const overviewRemoveInactiveTenant = data =>
  adminAxiosClient.post('api/overview/tenants/remove-inactive-tenant', { ...data })

export const adminListAzureDevices = params =>
  adminAxiosClient.get('api/overview/azure-devices', { params })
export const adminGetAzureDevice = id => adminAxiosClient.get(`api/overview/azure-devices/${id}`)
export const adminListGoogleDevices = params =>
  adminAxiosClient.get('api/overview/google-devices', { params })
export const adminGetGoogleDevice = id => adminAxiosClient.get(`api/overview/google-devices/${id}`)

export const adminCheckIntuneLicenceRevoked = id =>
  adminAxiosClient.get(`api/overview/check-intune-licence-revoked/${id}`)
export const adminValidateDomainToken = id =>
  adminAxiosClient.get(`api/overview/validate-google-token/${id}`)

// OVERVIEW > TENANT
export const adminAddGoogleTenant = payload =>
  adminAxiosClient.post('api/overview/tenants/register-google-domain', { ...payload })

// OVERVIEW > TENANT > DETAIL
export const adminSyncDevices = tenantId =>
  adminAxiosClient.post(`api/overview/tenants/${tenantId}/sync-devices`)
export const adminGetSyncDeviceStatus = tenantId =>
  adminAxiosClient.get(`api/overview/tenants/${tenantId}/sync-devices`)

// export const overviewHealthCheckPermission = tenantId =>
//   adminAxiosClient.get(`api/overview/health-check/${tenantId}/check-permissions`)
// export const overviewHealthCheckLastConnection = tenantId =>
//   adminAxiosClient.get(`api/overview/health-check/${tenantId}/check-last-connection`)
// export const overviewHealthCheckAppConnection = tenantId =>
//   adminAxiosClient.get(`api/overview/health-check/${tenantId}/check-app-connection`)
// export const overviewHealthCheckAutopilotCname = tenantId =>
//   adminAxiosClient.get(`api/overview/health-check/${tenantId}/check-autopilot-cname`)
// export const overviewHealthCheckSyncData = tenantId =>
//   adminAxiosClient.get(`api/overview/health-check/${tenantId}/sync-data`)

// OVERVIEW > REGISTER DEVICE SESSION
export const overviewGetListRegisterDeviceSessions = params =>
  adminAxiosClient.get('api/overview/device-register-sessions', { params })
export const overviewGetRegisterDeviceSessionDetail = id =>
  adminAxiosClient.get(`api/overview/device-register-sessions/${id}`)
export const overviewGetListOrganisations = () => adminAxiosClient.get('api/overview/organisations')

// OVERVIEW > ADMINITRATOR > INVITATION
export const overviewInviteAzureTenant = payload =>
  adminAxiosClient.post('api/overview/azure-registration-invitation', { ...payload })

export const overviewInviteGoogleDomain = payload =>
  adminAxiosClient.post('api/overview/google-registration-invitation', { ...payload })

export const overviewRoles = params => adminAxiosClient.get('api/overview/roles', { params })

export const overviewOrgs = params =>
  adminAxiosClient.get('api/overview/get-org-without-paginate', { params })

// OVERVIEW > ADMINITRATOR > OVERVIEW USERS
export const overviewSuperUsers = params =>
  adminAxiosClient.get('api/overview/overview-users', { params })
export const overviewSuperUsersDetail = id =>
  adminAxiosClient.get(`api/overview/overview-users/${id}`)
export const getAzureTenantsWithoutPaging = () =>
  adminAxiosClient.get('api/overview/azure-tenants-without-paging')

export const updateSuperUserRole = (id, payload) =>
  adminAxiosClient.put(`api/overview/overview-users/${id}/update-role`, payload)
export const updateDisableSuperUser = (id, payload) =>
  adminAxiosClient.post(`api/overview/overview-users/${id}/disable`, payload)

export const getDisabledSuperUsers = params =>
  adminAxiosClient.get('api/overview/overview-users-disable', { params })
// OVERVIEW > USERS > COMPANIES
export const overviewGetListCompanies = params =>
  adminAxiosClient.get('api/overview/companies', { params })
export const overviewCreateCompany = data =>
  adminAxiosClient.post('api/overview/companies', { ...data })
export const overviewUpdateCompany = (id, data) =>
  adminAxiosClient.put(`api/overview/companies/${id}`, { ...data })
export const overviewGetCompany = id => adminAxiosClient.get(`api/overview/companies/${id}`)
export const overviewGetUsersByCompanyId = (id, params) =>
  adminAxiosClient.get(`api/overview/companies/${id}/users`, { params })
export const overviewGetTenantsByCompanyId = (id, params) =>
  adminAxiosClient.get(`api/overview/companies/${id}/tenants`, { params })
export const overviewGetSchoolsByCompanyId = (id, params) =>
  adminAxiosClient.get(`api/overview/companies/${id}/schools`, { params })

// OVERVIEW > USERS > ROLES
export const overviewGetListRoles = params => adminAxiosClient.get('api/overview/roles', { params })
export const overviewCreateRole = data => adminAxiosClient.post('api/overview/roles', { ...data })
export const overviewUpdateRole = (id, data) =>
  adminAxiosClient.put(`api/overview/roles/${id}`, { ...data })
export const overviewGetRole = id => adminAxiosClient.get(`api/overview/roles/${id}`)

// OVERVIEW > USERS > PERMISSIONS
export const overviewGetAllPermissionsByGuard = guardName =>
  adminAxiosClient.get(`api/overview/permissions/guard/${guardName}`)
export const overviewGetAllGuard = () => adminAxiosClient.get('api/overview/guards')

export const overviewGetAllEntities = () =>
  adminAxiosClient.get(`api/overview/company-entities-without-paginate`)
