import { enqueueSnackbar } from 'notistack'
import { useQuery, useQueryClient } from 'react-query'
import organisationApi from '~/api/organisationApi'
import overviewTenantApi from '~/api/overviewTenantApi'
import { parseApiErrorMessage, removeEmptyValue } from '~/utils/helpers'

export const useGroupTagsQuery = () => {
  return useQuery(
    ['group_tag'],
    async () => {
      const { data } = await organisationApi.listGroupTagsApi()
      return { data: data.data }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useOverviewGroupTagsQuery = params => {
  return useQuery(
    ['overview_group_tags', params],
    async () => {
      const p = removeEmptyValue(params)
      const { data } = await overviewTenantApi.listGroupTagsApi(params.id, p)
      return { data: data.data }
    },
    {
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useHealthCheckGoogleDomainQuery = (serviceTenantId, setServiceTenantId) => {
  const queryClient = useQueryClient()
  return useQuery(
    ['overview_healthcheck_google_domain'],
    async () => {
      const { data } = await overviewTenantApi.healthCheckGoogleDomainApi(serviceTenantId)
      return data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data?.message ?? 'Health Check Updated!',
          variant: 'success'
        })
        setServiceTenantId(null)
        queryClient.invalidateQueries('tenant_details')
      },
      onError: e => {
        const message = parseApiErrorMessage(e)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false,
      enabled: !!serviceTenantId
    }
  )
}
