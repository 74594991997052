import { yupResolver } from '@hookform/resolvers/yup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Divider,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import { Stack } from '@mui/system'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import * as yup from 'yup'
import { LoadingButton } from '~/components/Button/LoadingButton'
import { GOOGLE_DEVICES } from '~/constants/Routes'
import { useOrganisationListOverviewQuery } from '~/pages/Organisations/Organisations/query'
import { useAllPreProvisionTokensQuery } from '~/pages/Organisations/PreprovisionToken/query'
import { useCreateGoogleDeviceMutation } from '../../mutation'
import { useGoogleHardwareModelListQuery } from '../../Overview/query'

const RegisterGoogleDevice = () => {
  const { t } = useTranslation()
  const schema = yup.object().shape({
    serial_number: yup.string().required(),
    hardware_model: yup.string().required(),
    domain: yup.string(),
    customer_id: yup.string(),
    token: yup.string().nullable(true),
    school: yup.string().required(t('validation.orgRequired'))
  })

  const { data: schoolData } = useOrganisationListOverviewQuery(true, {
    filter: true,
    validated: true
  })

  const schools = useMemo(() => {
    return schoolData?.map(option => ({
      label: option.name + ' - ' + option.external_id,
      external_id: option.external_id,
      entity_id: option.entity_id
    }))
  }, [schoolData])

  const [externalId, setExternalId] = useState(null)
  const [entityId, setEntityId] = useState(null)

  const { mutate: createNewDevices, isLoading } = useCreateGoogleDeviceMutation()
  const onSubmit = async values => {
    const submitData = {
      ...values,
      external_id: externalId,
      entity_id: entityId
    }

    createNewDevices(submitData)
  }

  const { data } = useGoogleHardwareModelListQuery()

  const { data: tokenData, refetch } = useAllPreProvisionTokensQuery()

  const hardwareModels = useMemo(() => {
    return (
      data?.map(v => {
        return { label: v.name, value: v.name }
      }) || [{ label: 'No options', value: '' }]
    )
  }, [data])

  const tokens = useMemo(() => {
    return (
      tokenData?.data?.map(v => {
        return { label: v.pre_provisioning_token, description: v.description }
      }) || [{ label: 'No options', value: '' }]
    )
  }, [tokenData])

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    resolver: yupResolver(schema)
  })

  const localization = 'pages.device'

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography>
          <NavLink to={GOOGLE_DEVICES}>{t(`${localization}.detail.deviceOverview`)}</NavLink>
        </Typography>
        <Typography>{t('button.register')}</Typography>
      </Breadcrumbs>
      <Divider style={{ marginBottom: 20 }} />
      <Paper
        sx={{
          px: 2,
          py: 2
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={2}>
            <TextField
              label={t(`${localization}.create.serialNumber`)}
              name='serial_number'
              {...register('serial_number')}
              error={!!errors.serial_number}
              helperText={<>{!!errors.serial_number && errors.serial_number?.message}</>}
            />
            <Controller
              name='hardware_model'
              control={control}
              defaultValue={null}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  options={hardwareModels}
                  renderInput={params => (
                    <TextField
                      inputRef={ref}
                      error={!!errors.hardware_model}
                      helperText={<>{!!errors.hardware_model && errors.hardware_model?.message}</>}
                      name='hardware_model'
                      label={t(`${localization}.create.hardwareModel`)}
                      {...params}
                    />
                  )}
                  {...register('hardware_model')}
                />
              )}
            />
            <Controller
              name='school'
              control={control}
              defaultValue=''
              render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  id='school'
                  noOptionsText={t('validation.noOrg')}
                  options={schools}
                  value={schools?.find(school => school.label === value) || null}
                  onChange={(e, selectedOption) => {
                    onChange(selectedOption ? selectedOption.label : '')
                    setExternalId(selectedOption?.external_id || null)
                    setEntityId(selectedOption?.entity_id || null)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Organisation'
                      error={!!error}
                      helperText={error?.message || ''}
                      inputRef={ref}
                    />
                  )}
                />
              )}
            />

            <Accordion
              sx={{
                border: '1px solid rgb(230, 235, 241)',
                mt: 3,
                width: '100%'
              }}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='advanced-fields-content'
                id='advanced-fields-header'
              >
                <Typography variant='h8'>{t(`${localization}.advOpt`)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack gap={2}>
                  <Controller
                    name='token'
                    control={control}
                    defaultValue={null}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                      <Autocomplete
                        options={tokens}
                        freeSolo
                        getOptionLabel={option => option.label}
                        renderOption={(props, option) => (
                          <Box
                            component='li'
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <b>{option.label}</b>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {option.description}
                          </Box>
                        )}
                        renderInput={params => (
                          <TextField
                            inputRef={ref}
                            error={!!errors.token}
                            helperText={<>{!!errors.token && errors.token?.message}</>}
                            name='token'
                            label={t(`${localization}.preProvisioningToken`)}
                            {...params}
                          />
                        )}
                        onOpen={() => {
                          refetch()
                        }}
                        {...register('token')}
                      />
                    )}
                  />
                  <TextField
                    label={t(`${localization}.create.customerId`)}
                    name='customer_id'
                    {...register('customer_id')}
                    error={!!errors.customer_id}
                    helperText={<>{!!errors.customer_id && errors.customer_id?.message}</>}
                  />
                  <TextField
                    label={t(`${localization}.create.domain`)}
                    name='domain'
                    {...register('domain')}
                    error={!!errors.domain}
                    helperText={<>{!!errors.domain && errors.domain?.message}</>}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Stack direction='row-reverse'>
              <LoadingButton variant='contained' type='submit' loading={isLoading}>
                {t('button.create')}
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </>
  )
}

export default RegisterGoogleDevice
