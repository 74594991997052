import PropTypes from 'prop-types'
import useTable from '~/hooks/useTable'
import StudentGroupTag from '.'
import { useOverviewGroupTagsQuery } from '../../GroupTag/query'

const OverviewEditGroupTag = ({ open, setOpen, organisationId, organisationDetail }) => {
  const { data: overviewStudentGroupTags } = useTable({
    query: useOverviewGroupTagsQuery,
    queryAttributes: {
      id: organisationDetail?.tenant_id,
      type: 'default_group_tag'
    }
  })

  const { data: overviewGroupTagsLoaner } = useTable({
    query: useOverviewGroupTagsQuery,
    queryAttributes: {
      id: organisationDetail?.tenant_id,
      type: 'default_group_tag_loaner'
    }
  })

  const { data: overviewGrouptagStaff } = useTable({
    query: useOverviewGroupTagsQuery,
    queryAttributes: {
      id: organisationDetail?.tenant_id,
      type: 'default_group_tag_staff'
    }
  })

  return (
    <StudentGroupTag
      defaultStudentGroupTags={overviewStudentGroupTags}
      groupTagsLoaner={overviewGroupTagsLoaner}
      grouptagStaff={overviewGrouptagStaff}
      open={open}
      setOpen={setOpen}
      organisationId={organisationId}
      organisationDetail={organisationDetail}
    />
  )
}

OverviewEditGroupTag.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  organisationId: PropTypes.number,
  organisationDetail: PropTypes.object
}

export default OverviewEditGroupTag
