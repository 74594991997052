import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  bulkDeleteDevicesByCsvApi,
  bulkDeleteDevicesByCsvDownloadApi,
  bulkDeleteDevicesByCsvUploadApi,
  createDeviceApi,
  createGoogleDeviceApi,
  reRegisterApi
} from '~/api'
import { GOOGLE_DEVICES, AZURE_DEVICES } from '~/constants/Routes'
import { parseApiErrorMessage } from '~/utils/helpers'

export const useCreateDeviceMutation = () => {
  const navigate = useNavigate()
  return useMutation(
    ['device_create'],
    async payload => {
      return await createDeviceApi(payload)
    },
    {
      onSuccess: data => {
        let message = 'Successfully created device'
        if (data.status === 208) {
          message = data.data.message
        }
        enqueueSnackbar({
          message,
          variant: 'success'
        })

        navigate(AZURE_DEVICES)
        return data.data?.data
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useReRegisterDeviceMutation = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(
    ['device_re_register'],
    async id => {
      return await reRegisterApi(id)
    },
    {
      onSuccess: data => {
        let message = 'Successfully re-register device'
        if (data.status === 208) {
          message = data.data.message
        }
        enqueueSnackbar({
          message,
          variant: 'success'
        })
        queryClient.invalidateQueries('azure_devices_overview')
        navigate(AZURE_DEVICES)
        return data.data?.data
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useCreateGoogleDeviceMutation = () => {
  const navigate = useNavigate()
  return useMutation(
    ['google_device_create'],
    async payload => {
      return await createGoogleDeviceApi(payload)
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: 'Successfully created device',
          variant: 'success'
        })

        navigate(GOOGLE_DEVICES)
        return data.data?.data
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}

export const useBulkDeleteDeviceByCsvUploadMutation = id => {
  const { i18n } = useTranslation()
  return useMutation(
    ['upload_bulk_delete_file', id],
    async file => {
      const data = new FormData()
      data.append('file', file)
      const response = await bulkDeleteDevicesByCsvUploadApi(id, data, i18n.language)
      return response.data
    },
    {
      retry: false
    }
  )
}

export const useBulkDeleteDeviceByCsvDownloadMutation = id => {
  return useMutation(
    ['download_bulk_delete_file', id],
    async () => {
      const response = await bulkDeleteDevicesByCsvDownloadApi(id)
      return new Blob([response.data], {
        type: 'text/plain',
        encoding: 'UTF-8'
      })
    },
    {
      retry: false,
      onError: error => {
        const message = parseApiErrorMessage(error) || 'Failed to download file'
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      onSuccess: data => {
        const url = window.URL.createObjectURL(data)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'not_recognized_and_managed_by_TRC.txt')
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      }
    }
  )
}

export const useBulkDeleteDeviceByCsvMutation = id => {
  const queryClient = useQueryClient()
  return useMutation(
    ['bulk_delete_device_by_csv', id],
    async data => {
      const response = await bulkDeleteDevicesByCsvApi(id, data)
      return response.data
    },
    {
      onSuccess: data => {
        enqueueSnackbar({
          message: data.message || 'Successfully deleted devices',
          variant: 'success'
        })
        queryClient.invalidateQueries('azure_devices_overview')
      },
      onError: error => {
        const message = parseApiErrorMessage(error)
        enqueueSnackbar({
          message,
          variant: 'error'
        })
      },
      retry: false
    }
  )
}
