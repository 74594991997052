import adminAxiosClient from './adminBase'

class OverviewTenantApi {
  listGroupTagsApi = (id, params) =>
    adminAxiosClient.get(`api/overview/tenants/${id}/group-tags`, { params })

  healthCheckGoogleDomainApi = serviceTenantId =>
    adminAxiosClient.get(`api/overview/tenants/${serviceTenantId}/healthcheck-google-domain`)

  getListTenantFilter = () => adminAxiosClient.get(`api/overview/tenants-without-paging`)

  switchDeviceNamePrefix = id =>
    adminAxiosClient.put(`api/overview/tenants/${id}/switch-device-name-flow`)
}

export default new OverviewTenantApi()
