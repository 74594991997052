import { getAzureGroupTag } from '~/api'
import organisationApi from '~/api/organisationApi'
import { useCustomQuery as useQuery } from '~/hooks/useQuery'
import { removeEmptyValue } from '~/utils/helpers'

export const useAzureGroupTagQuery = params => {
  return useQuery(['organisation_group_tags', params], async () => {
    const p = removeEmptyValue(params)
    const { data } = await organisationApi.listGroupTagsApi(p)
    return data
  })
}

export const useDeviceGroupTagQuery = () => {
  return useQuery(['device_group_tags'], async () => {
    const { data } = await getAzureGroupTag()
    return data
  })
}
